import React, { useState, useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"
import { IoCloseCircle } from "@react-icons/all-files/io5/IoCloseCircle"
import LeadBannerImg from "../../assets/images/lead-banner.jpg"
import { Button } from "../reusableComponents/Button"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import { Link } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import emailjs from "emailjs-com"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"

const LeadPopup = ({ open, onClose }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("") // Ensure phone is always a string
  const [defaultCountry, setDefaultCountry] = useState("US")
  const [agreement, setAgreement] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [formIsValid, setFormIsValid] = useState(false)
  const [hasBooktoPublish, setHasBooktoPublish] = useState("")
  const [loading, setLoading] = useState(false)

  const reCaptchaElement = useRef(null)

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [open])

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/")
        const data = await response.json()
        if (data.country_code) {
          setDefaultCountry(data.country_code)
        } else {
          console.error("Country code not found.")
        }
      } catch (error) {
        console.error("Error fetching country code:", error)
      }
    }

    fetchCountryCode()
  }, [])

  useEffect(() => {
    const isFormValid =
      Boolean(firstName.trim()) &&
      Boolean(lastName.trim()) &&
      Boolean(email.trim()) &&
      Boolean(phone.trim()) &&
      agreement &&
      Boolean(recaptchaToken) &&
      Boolean(hasBooktoPublish)

    setFormIsValid(isFormValid)
  }, [
    firstName,
    lastName,
    email,
    phone,
    agreement,
    recaptchaToken,
    hasBooktoPublish,
  ])

  const handleFirstNameChange = e => setFirstName(e.target.value)
  const handleLastNameChange = e => setLastName(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  const handlePhoneChange = value => setPhone(value || "") // Ensure value is a string
  const handleAgreementChange = () => setAgreement(!agreement)
  const handleHasBooktoPublishChange = e => setHasBooktoPublish(e.target.value)

  const handleRecaptchaChange = token => {
    setRecaptchaToken(token)
  }

  const onDownloadGuide = async e => {
    e.preventDefault()
    e.persist()
    if (formIsValid) {
      setLoading(true) // Show loader

      // Create a temporary anchor element
      const link = document.createElement("a")
      link.href = "/pdf/Publishing Industry Unmasked.pdf" // Adjust the path if necessary
      link.download = "Publishing Industry Unmasked.pdf" // This is the name of the file when downloaded

      // Append the anchor to the body
      document.body.appendChild(link)

      // Trigger a click event to start the download
      link.click()

      // Clean up
      document.body.removeChild(link)

      try {
        const data = await emailjs.sendForm(
          "service_psjv8k9",
          "template_qyu9e4j",
          e.target,
          "rIHXulH9lFawIJ1jI"
        )
        if (data.status === 200) {
          e.target.reset()
          toast.success(
            "Thank you for trusting writers way solutions. Our team will be in touch to you as soon as possible.!"
          )
        }
      } catch (error) {
        console.log(error.text)
        toast.error("Failed to send email. Please try again later.")
      }

      // Reset form and state
      setFirstName("")
      setLastName("")
      setEmail("")
      setPhone("")
      setAgreement(false)
      setRecaptchaToken(null)
      setHasBooktoPublish("")
      setFormIsValid(false)

      // Reset ReCAPTCHA
      if (reCaptchaElement.current) {
        reCaptchaElement.current.reset()
      }

      // Hide loader
      setLoading(false)
    } else {
      console.log("Form is not valid")
    }
  }

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   // Handle form submission
  //   console.log("Form submitted")
  // }

  if (!open) return null

  return (
    <>
      <ToastContainer />
      <Overlay>
        <Main>
          <CloseIconWrapper onClick={onClose}>
            <IoCloseCircle
              css={`
                width: 100%;
                height: 100%;
                color: #222;
              `}
            />
          </CloseIconWrapper>
          <BannerContainer>
            <BannerImgWrapper>
              <Img
                src={LeadBannerImg}
                alt="writers way solutions lead banner"
              />
            </BannerImgWrapper>
            <BannerContentWrapper>
              <BannerTitle>
                THE LITERARY
                <br /> <span>WORLD AWAITS</span>
              </BannerTitle>
              <BannerDescription>
                Self-publishing your book is most definitely not an easy path,
                but you don’t have to make the journey all by yourself. Writers
                Way Solutions has a range of publishing packages that are
                first-rate and are designed to suit every author’s needs. Learn
                more in our free publishing guide.
              </BannerDescription>
            </BannerContentWrapper>
          </BannerContainer>
          <FormContainer>
            <Form onSubmit={onDownloadGuide}>
              <div
                css={`
                  display: flex;
                  gap: 1rem;
                  flex-direction: column;
                `}
              >
                <SelectWrapper>
                  <select
                    value={hasBooktoPublish}
                    onChange={handleHasBooktoPublishChange}
                    id="has-book-to-publish"
                    name="from_has_manuscript"
                  >
                    <option value="" disabled>
                      Do you have a manuscript that is ready to publish?
                    </option>
                    <option value="I am ready to publish today">I am ready to publish today.</option>
                    <option value="I will be ready in 1-3 months">I will be ready in 1-3 months.</option>
                    <option value="I will be ready in 4-6 months">I will be ready in 4-6 months.</option>
                    <option value="I will be ready in more than 6 months">
                      I will be ready in more than 6 months.
                    </option>
                    <option value="I don't have a book manuscript">
                      I don't have a book manuscript.
                    </option>
                  </select>
                </SelectWrapper>

                <Input
                  type="text"
                  id="firstname"
                  name="from_first_name"
                  placeholder="First Name"
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
                <Input
                  type="text"
                  id="lastname"
                  name="from_last_name"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
                <Input
                  type="email"
                  id="email"
                  name="from_email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <CustomPhoneInput
                  name="from_number"
                  defaultCountry={defaultCountry}
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={handlePhoneChange}
                />
                <div
                  css={`
                    display: flex;
                    align-items: flex-start;
                    gap: 0.5rem;
                  `}
                >
                  <input
                    type="checkbox"
                    id="agreement"
                    name="pop_up_lead_agreement"
                    checked={agreement}
                    onChange={handleAgreementChange}
                    css={`
                      margin-top: 0.2rem;
                      cursor: pointer;
                    `}
                  />
                  <Agreement htmlFor="pop_up_lead_agreement">
                    Receive periodic e-mail updates
                  </Agreement>
                </div>
                <Policy>
                  By Clicking "DOWNLOAD THE GUIDE", you are providing your
                  electronic signature, voluntarily authorizing Writers Way
                  Solutions and its affiliates to contact you using a manual or
                  automated telephone dialing system and send you an
                  advertisement or telemarketing messages by email or
                  text/SMS/MMS message to the address and phone number you have
                  provided above. You are not required to agree to this in order
                  to buy products or services from Writers Way Solutions. You
                  certify that you are over the age of eighteen (18). You'll get
                  up to ten (10) messages per month. Standard message and data
                  rates may apply. Click here to view our{" "}
                  <NavLink to="/privacy">
                    SMS Notification Opt-In Policy,
                  </NavLink>{" "}
                  <NavLink to="/compliance-policy">Privacy Policy and </NavLink>{" "}
                  and
                  <NavLink to="/terms-cons">Terms & Conditions</NavLink>.
                </Policy>

                <ReCAPTCHA
                  ref={reCaptchaElement}
                  sitekey="6LfnMS0qAAAAAImYzuzp8lHC5z6h7TWGs74b3kYF"
                  onChange={handleRecaptchaChange}
                />
              </div>
              <ButtonContainer>
                {/* <Button
                as="button"
                onClick={onDownloadGuide}
                round="true"
                css={`
                  width: 100%;
                `}
                disabled={!formIsValid}
              >
                DOWNLOAD THE GUIDE
              </Button> */}
                <StyledButton
                  as="button"
                  type="submit"
                  round="true"
                  loading={loading}
                  disabled={!formIsValid || loading}
                >
                  <span className="button-text">DOWNLOAD THE GUIDE</span>
                  <ButtonLoader className="button-loader" />
                </StyledButton>
              </ButtonContainer>
            </Form>
          </FormContainer>
        </Main>
      </Overlay>
    </>
  )
}

export default LeadPopup

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(43, 43, 43, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  z-index: 999;
  top: 0;
  left: 0;
`
// Keyframes for sliding down effect
const slideDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

// Styled components
const Main = styled.main`
  width: 100%;
  max-width: 951px;
  min-height: 798px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  animation: ${slideDown} 0.5s ease-out forwards; // Apply the animation

  @media screen and (max-width: 967px) {
    max-height: unset;
    min-height: unset;
    height: 100%;
    margin: auto 0;
    overflow-y: auto;
  }

  @media screen and (max-width: 620px) {
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
`
const BannerContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;

  @media screen and (max-width: 620px) and (max-height: 667px) {
    height: 45%;
  }

  @media screen and (max-width: 620px) {
    height: 40%;
  }
`

const BannerContentWrapper = styled.section`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
  padding: 50px 40px;

  @media screen and (max-width: 620px) {
    padding: 30px 20px;
    gap: 1rem;
  }
`

const BannerTitle = styled.h4`
  font-size: 35px;
  font-family: ${props => props.theme.fonts.secondary} !important;
  line-height: 55px;
  color: #fff;
  font-weight: 500;

  span {
    font-weight: 600;
    font-size: 45px;
  }

  @media screen and (max-width: 620px) {
    font-size: 20px;
    line-height: 32px;

    span {
      font-size: 30px;
    }
  }
`

const BannerDescription = styled.p`
  font-size: 16px;
  font-family: ${props => props.theme.fonts.secondary} !important;
  line-height: 55px;
  color: #fff;
  font-weight: 500;
  line-height: 24px;
  text-align: justify;

  @media screen and (max-width: 620px) {
    font-size: 12px;
    line-height: 20px;
  }
`

const BannerImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(63, 61, 86, 0.9);
    z-index: 1;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0; /* Ensure the image is behind the overlay */
`

const FormContainer = styled.section`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 50px 35px;
  justify-content: space-between;
  gap: 2rem;

  @media screen and (max-width: 620px) {
    padding: 25px 20px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  height: auto;

  @media screen and (max-width: 620px) {
    padding-bottom: 2rem;
    box-sizing: border-box;
  }
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  box-sizing: border-box;
  height: 100%;
  justify-content: space-between;
`

// const FormContentWrapper = styled.div`
//  display: flex;
//  align-items: center;
//  gap: 1rem;
//  justify-content: space-between;
// `

const Input = styled.input`
  padding: 0.9rem 1rem;
  outline: none;
  width: 100%;
  border-radius: 10px !important;
  border-width: 0px;
  border: none;
  background: rgba(12, 15, 74, 0.04);
`

const Agreement = styled.label`
  font-size: 12px;
  font-style: italic;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  font-weight: 400;
  color: #3d3f3f;
`

const Policy = styled.p`
  font-size: 10px;
  font-style: italic;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  font-weight: 400;
  color: #3d3f3f;
  text-align: justify;
`

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;

  select {
    width: 100%;
    padding: 0.9rem 2.5rem 0.9rem 1rem; /* Adds padding to the right for the custom arrow */
    appearance: none; /* Removes default browser styling for select */
    outline: none;
    border-radius: 10px !important;
    border: none;
    background: rgba(12, 15, 74, 0.04);
    background-image: none; /* Ensures default arrow is hidden */
    position: relative;
  }

  /* Custom arrow */
  &::after {
    content: "▼"; /* Custom arrow */
    font-size: 12px;
    color: #000;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Ensures clicking the arrow still opens the select */
  }
`

const NavLink = styled(Link)`
  font-size: 10px;
  letter-spacing: 1.3px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
  font-weight: 500;
  color: ${props => props.theme.colors.main1};
  cursor: pointer;
  text-decoration: underline;
`

// const Select = styled.select`
//   padding: 0.9rem 1rem;
//   outline: none;
//   width: 100%;
//   border-radius: 20px;
//   border-width: 0px;
//   border: none;
//   background: rgba(12, 15, 74, 0.04);
// `

const CloseIconWrapper = styled.div`
  cursor: pointer;
  width: 27px;
  height: 27px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CustomPhoneInput = styled(PhoneInput)`
  width: 100%;
  padding: 0 1rem;
  height: 46px;
  border-radius: 10px;
  background: rgba(12, 15, 74, 0.04);

  .PhoneInputInput {
    border: none;
    background: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    box-sizing: border-box;
  }

  .PhoneInputCountrySelect {
    border: none;
    background: transparent;
  }
`

const ButtonLoader = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 3px solid #333;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StyledButton = styled(Button)`
  position: relative;
  width: 100%;

  .button-text {
    visibility: ${props => (props.loading ? "hidden" : "visible")};
  }

  .button-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: ${props => (props.loading ? "visible" : "hidden")};
  }
`
